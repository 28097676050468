
@font-face {
  font-family: "Avenir Next";   /*Can be any text*/
  src: local("Avenir Next"),
    url("./assets/fonts/Avenir Next.ttf") format("truetype");
}

:root {
  --center-lane: 150px;
  --right-lane: 300px;
  --dark-highlight: #C6EBBB;
  --light-highlight: #E2FFDC;
}

.an-oms-demo {
  background: #F2F2F2;
}

body .inputAndDiagram {
  background: #F2F2F2;
}

.topStrip {
  background: white;
  height: 74px;
}

#root {
  margin-left: 10px;
}

.graph-row-header-text {
  color: #005599!important;
  font-size: 14px;
  font-weight: 600!important;
}

/* Rectangle representing an actor in the graph */
.actor-rect {
  rx: 5px;
}

.actor-text {
  pointer-events: none;
}

/* not selected actor */
.actor-plain {
  fill: #d3ecff; /* blue */
}

/* selected actor because its the source of flow */
.actor-selected-src {
  fill: var(--dark-highlight);
}

/* selected actor because its the destination of flow */
.actor-selected-dst {
  fill: var(--light-highlight);
}

.connector-arc {
  fill: none;
  stroke: black;
  shape-rendering: geometricPrecision;
}

.connector-line {
  fill: none;
  stroke: black;
  shape-rendering: geometricPrecision;
}

/* not selected line or arc */
.connector-plain {
  stroke-width: 1px;
  stroke-dasharray: 4;
}

/* selected line or arc */
.connector-selected {
  stroke-width: 1px;
}

.document-icon, .envelope-icon {
  shape-rendering: geometricPrecision;
}

.current-icon {
  stroke: black;
  stroke-width: 1px;
  fill: var(--dark-highlight);
}

.past-icon {
  stroke: black;
  stroke-width: 1px;
  fill: #d3ecff; /* blue */
}

.future-icon {
  stroke-width: 2px;
  stroke: lightgray;
  fill: white;
}

.ledger-grid {
  pointer-events: none;
  stroke: black;
  fill: white;
}

.ledger-text {
  color: black;
}

.ledger-text-future {
  color: lightgray;
}

.ledger-align-number {
  text-align: right;
}

.ledger-text-negative {
  color: red
}

.ledger-text-positive {
  color: green
}

.ledger-text-future-negative {
  color: lightpink
}

.ledger-text-future-positive {
  color: lightgreen
}

.centeredText {
  text-align: center;
  color:#005599!important;
	font-size: 24px;
    font-weight: 600;
    margin: 0!important;
    padding: 0!important;
    padding-bottom: 4px!important;
}

.document-title {
  font-weight: bold;

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  background: #D3ECFF;
  justify-self: stretch;
}

.document-contents-description {
  text-wrap: none;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 12;
  grid-row-end: 16;
  white-space: normal;
  border-top: 1px solid black;
  border-left: 2px solid floralwhite;
  border-right: 2px solid floralwhite;
}

.document-contents-header {
  align-self: start;
  justify-self: right;
  font-weight: bold;
}

.document-contents-data {
  justify-items: start;
  justify-content: start;
}

.document-contents-doctype {
  justify-items: start;
  justify-content: start;
  line-break: normal; /* so that these lines can wrap and don't mess up the description formatting */
  white-space: pre-wrap;
}

.document-contents-icon {
  justify-self: right;
  font-weight: bold;
}

.document-contents {
  justify-items: start;
  justify-content: start;
}

.document-table {
  display: grid;

  column-gap: 2px;
  row-gap: 2px;

  user-select: none;
  white-space: nowrap;

  align-content: center; /* center on bottom of line *!*/
  align-items: center; /* center on bottom of line *!*/

  justify-content: start;
  justify-items: start;

  border: 1px solid black;

  font-family: 'Avenir Next', sans-serif !important;
  font-size: 12px;

  background: floralwhite;
}

.ledger-table-title {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
  background: #D3ECFF;
}

.ledger-table-header {
  text-align: center;
  display: flex; /* use flexbox rules */
  align-items: flex-end; /* center on bottom of line */
  justify-content: space-around;
  background: #D3ECFF;
}

.ledger-table {
  grid-column: 3 / 5;
  user-select: none;
  white-space: nowrap;
  grid-column-gap: 2px;
  display: grid;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  font-family: 'Avenir Next', sans-serif !important;
  font-size: 12px;
  text-align: left;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  background: repeating-linear-gradient(
    #F0F0F0 1px 18px,
    white 18px 36px
  );
}

span {
  font-size: 14px;
  font-family: 'Avenir Next', sans-serif !important;
}

h1, h2, h3, h4, h5, li, label, select, th, input, p, span {
  font-family: 'Avenir Next' !important;
}

text {
  font-size: 12px;
  font-family: 'Avenir Next' !important;
}

h3 {
  margin-bottom: 0;
  text-align: left;
}


.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.MuiTableCell-root {
  font-family: 'Avenir Next', sans-serif !important;
  font-size: 14px !important;
}

.MuiTableCell-head {
  
}

.contentContainer {
  margin: 20px;
}

.row div { height: 50px; }

.anoms button {
  background-color: #1457BD !important;
  width: 200px !important;
  height: 44px !important;
  border: none !important;
  color: white !important;
  
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 14px !important;
  
  font-family: 'Avenir Next', sans-serif !important;
}

.anoms form {
  margin: 15px auto;
  padding: 20px;
  height: 775px !important;
  display: inline-block !important;
  background: white;
}

.anoms form > div {
    display: flex;
    flex-flow: row nowrap;
    line-height: 2em;
      margin: 5px;
}

.anoms form>div>label {
  float: left;
  font-size: 14px;
  font-weight: 600!important;
  line-height: 16px;
  padding: 12px 10px;
  text-align: right;
  width: 200px;
  color: #005599!important;
}

.anoms.box {
  /* display: inline-block; */
  background-color: #cfe3fc;
  width: 50px;
  height: 18px;
  margin: 100px;
  font-size: 7px;
  font-family: 'Avenir Next', sans-serif !important;
  text-align: center;
  vertical-align: middle;
  line-height: normal;
  position: absolute;
}

#submitButton{
  margin-left: 110px;
  margin-top: -4px;
  padding: 20px;
}

#submitButton span{
  background: none;
}

#clientOne{
  margin-left: var(--center-lane);
  position: absolute;
}

#omsOne {
  margin-top: 200px;
  margin-left: var(--center-lane);
}

#omsTwo {
  margin-top: 200px;
  margin-left: var(--right-lane);
}

#exchange {
  margin-left: var(--center-lane);
  margin-top: 300px;
}

#abc {
  margin-left: var(--center-lane);
  margin-top: 400px;
}

#custodianA {
  margin-left: 0px;
  margin-top: 300px;
}

#custodianB {
  margin-left: var(--right-lane);
  margin-top: 300px;
}

.active-box {
  background-color: green;
}


.anoms #ListBoxSelect {
  border: 1px solid #D6D6D6!important;
  background: #fff;
  border: none;
  color: #000;
  font-size: 15px;
  height: 70px;
  padding: 5px 8px;
  text-align: left;
  width: 280px!important;
}

#SelectDiv {
  float: left;
}

.anoms > option:checked {
  background: #E3F3FF!important;
}

.anoms .form > div > input {
  height: 38px;
  padding: 0;
}

.anoms > form > input[type='radio']{
  width: 20px;
  height: 20px;
  }
  
.ledgerTable td, .ledgerTable th{
  font-size: 10px;
}

.contentCard {
  background-color: white !important;
  display: inline;
}

.logAction {
  text-transform:capitalize;
}

.memorializedDocument {
  display: inline-block;
  margin-right: 5px;
  /* padding-top: 60px; */
}

.memorializedDocumentContainer{
  margin-left: -19px;
  margin-top: -140px;
  height: 126px;
  background: #fff;
  padding:14px;
  font-size:14px;
  font-weight:600 !important;
  width: 1600px;
  padding:18px; 
}

.memorializedItem {
  float: left;
  padding: 0;
  font-weight: 400;
}

.memorializedDocumentTitle {
  font-family: 'Avenir Next', Helvetica, Arial;
    font-size: 14px; 
    font-weight: 400;
    padding: 0px;
    margin: 0px;
}

.memorializedDocumentTimestamp {
  font-family: 'Avenir Next', Helvetica, Arial;
    font-size: 12px;
    font-weight: 400;
    padding: 0px;
    margin: 0px;
}

.memorializedDocumentArrow {
  position: relative;
  margin-top: -20px;
  padding: 12px;
}

.memorializedDocumentContainer p {
  margin-top: -3px;
}
#network-dropdown-container {
  margin-top: -35px;
}
#memorialized-documents-title {
    font-family: 'Avenir Next', Helvetica, Arial;
    font-size: 14px; 
    color: #005599!important;
    font-weight: 600; 
    margin: 0 0 20px; 
    padding: 0;
    text-align: left;
}

#memorializedTable {
  margin-top: 0px;
}

.documentDetailCard {
  width: 300px;
  float: left;
  height: 210px;
  background: #E8FFD6;
  padding: 20px 0px 0px 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600 !important;

}

#diagram-detail-title {
  padding: 0;
  float: left;
  font-size: 18px;
  font-weight: 600 !important;
}

#diagram-icon {
  float: left;
  padding-left: 6px;
}

#documentDetails {
  float: left;
  padding-left: 6px;
  padding-right: 6px;
}

#balancesTable {
  width: 590px;
  height: 200px;
  background: #FDF1F0;
  padding: 20px 0 0 20px;
}

.balancesTableCell {
  background: #FDF1F0;
  font-size: 14px !important;
  font-weight: 300 !important;
  text-decoration: line-through;
  padding: 0
}

.inlineContent {
  display: inline-block;
}


.anoms #orderTicketTitle {
  font-size:14px;
  font-weight: 600!important; 
  color:#005599!important;
  font-family: Avenir Next,Helvetica,Arial,sans-serif!important;
}

.diagram-component {
  margin: 15px;
  height: 775px !important;
  width: 1200px;
  background-color: #fff;
  padding: 10px auto;
  display: inline-block;
  padding:18px; 
  position: absolute;
}

.selectedLog {
  background-color: var(--dark-highlight);
}

.rightFloatText{
  float: right;
  align: right;
  padding-right: 20px !important;
}

.scrollableTable {
  overflow-y: hidden;
  scroll-behavior: initial;
}

#logo {
  padding-top:10px; padding-left:10px;
}

.headerCell {
  font-family: Avenir Next,Helvetica,Arial,sans-serif!important;
    font-size: 12px!important;
    font-weight: 600!important;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgb(224, 224, 224);
    text-align: right;
    padding: 16px;
    color: #000000!important;
    flex-direction: row-reverse;
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: rgb(255, 255, 255);
    width: 120px;
}

#dateCell {
  font-family: Avenir Next,Helvetica,Arial,sans-serif!important;
  font-weight: 400!important;
  font-size: 13px!important;
  line-height: 1.0!important;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(224, 224, 224);
  text-align: left;
  padding: 15px!important;
  color: #000000!important;

}

.tooltip-container #my-tooltip p {
  font-size: 16px !important;
  font-family: "Avenir Next" !important;
  z-index: 10000 !important;
  color: white !important;
}

#disclaimer-tooltip {
  font-size: 16px !important;
  font-family: "Avenir Next" !important;
  z-index: 10000 !important;
}

#disclaimer-tooltip p {
  color: white !important;
}

#disclaimer-tooltip p {
  font-size: 16px !important;
  font-family: "Avenir Next" !important;
  z-index: 10000 !important;
}



#pageTitle {
  font-size: 28px;
  font-weight: 600;
}

#pageSubtitle {
  font-family: 'Avenir Next', Helvetica, Arial;
  color:#005599!important;
	font-size: 14px;
  font-weight: 100;
  /* margin: 0!important; */
  margin-left: 382px;
  text-align: center;
  margin-top: 0px;
  padding: 0!important;
}

#theAuditLog {
  margin-top: -38px;
}


.an-oms-demo button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-family: 'Avenir Next', Helvetica, Arial;
}

.dropdown {
  padding: 14px!important;
  font-size: 14px!important;
  width: 240px!important;
  appearance: none;
  user-select: none;
  cursor: pointer;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  font-family: Avenir Next,Helvetica,Arial,sans-serif!important;
}

an-oms-demo .radioField {
  margin-top: -20px !important;
}

.radioField .interiorRadioLabel {
  text-align: left !important;
}

.secondChoiceMargin {
  margin-left: 9px;
}

#navigationArrowBox {
  float: right;
  margin-top: -70px;
  padding-right: 57px;
}

#disclaimer {
  text-align:center; padding-top:24px;
}

#page_title {
  text-align:center; padding-top:10px;
}

#title {
  font-size:24px!important; color:#005599!important; font-weight:600; margin:0px!important; padding:0px!important;
}

#subtitle {
  font-size:13px; color:#005599; font-weight:400;
}

#timeArea {
  text-align:center; padding-top:18px;
}

#leftArrow {
  float:left; padding-top:8px;
}
#timestamp {
  float:left; padding:0px 14px 0px 14px; font-size:13px; color:#005599; font-weight:600;
}
#rightArrow {
  float:left; padding-top:8px;
}

#beforeTicketLogo {
  text-align: center;
}

#dropdown {
  text-align:center; padding-top:10px;
}

#link{
  font-size:13px; color:#005599; font-weight:400;
  font-family: 'Avenir Next', sans-serif !important;
}
