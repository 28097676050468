@font-face {
  font-family: 'Open Sans';
  src: url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap);
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

