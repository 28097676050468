

/* BEGIN INLINE STYLE TRANSFER */
#banner-section-header {
	padding:1em 2em!important; 
	width:60%;
}

#banner-section-header-h3 {
	color:#fff!important; 
	font-style:italic!important;
	font-size:18px!important;
}

#blog-section-header-h2 {
	font-weight:500!important;
}

#blog-section-box-header-h3 {
	font-style:italic!important;
	color:#5d5d5d!important;
}

#blog-section-box-p {
	line-height:24px!important;
}

#footer-contact-p {
	color: #c5c4c4;
}

#about-content-header {
	margin:0px; 
	padding:0px;
}

#about-content-h2 {
	margin:0px;
	padding:0px;
	padding-bottom:18px;
}

#about-bios-header {
	margin:0px;
	padding:0px;
}

#about-bios-h3-font {
	font-size: 18px!important;
	font-style:italic!important;
}

#about-bios-ul {
	margin:0px;
	padding:10px;
}

#no-margin-no-padding {
	margin: 0;
	padding: 0;
}

#h2-bottom-padding {
	padding-bottom:18px;
	margin: 0;
	padding: 0;
}

#faq-blue {
	color:#005599;
}

p {
	font-family: 'Source Sans Pro' !important;
	font-weight: 300;
	color: #5D5D5D;
	font-size: 14pt;
	line-height: 1.75em;
}

h2, h3{
	font-family: 'Source Sans Pro' !important;
}
